import { nanoid } from 'nanoid';
import moment from 'moment';

// HEAD DATA
export const headData = {
  title: 'Nkosenhle Godson Duma | Fullstack Software Developer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome :)', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Greetings, my name is',
  name: 'Nkosenhle Godson Duma',
  subtitle: 'Fullstack Software Developer',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraph:
    'Diligent, flexible and dedicated young professional looking apply my skills in computer science and software engineering in a thriving and challenging environment while learning in the industry. ' +
    'Proficient in collaborative work, communication, and project-planning. These skills are the backbone of my work-ethic and have been vital to my success as Software Engineer.',
  interests: [
    'Reading',
    'Fullstack Software Development',
    'Software Architecture',
    'Gaming',
    'Machine Learning & Big Data',
    'Artificial Intelligence',
    'Image Processing',
    'Networking',
  ],
  skills: [
    {
      name: 'Java',
      level: 75, // out of 100
    },
    {
      name: 'Python',
      level: 75,
    },
    {
      name: '(Java/Type)Script',
      level: 77,
    },
    {
      name: 'Software Design',
      level: 69,
    },
    {
      name: 'Solutions Architecture',
      level: 55,
    },
    {
      name: 'SQL',
      level: 55,
    },
    {
      name: 'AWS',
      level: 70,
    },
    {
      name: 'CounterStrike: Global Offensive',
      level: 'Legendary Eagle',
      disabled: true,
    },
  ],
};

// TODO: Create public (or publically accessible) projects.
// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: 'Reach Out',
  email: 'personal@godsonduma.com',
};

export const workExperienceData = [
  {
    companyName: 'Amazon Web Services',
    companyLogo:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Amazon_Web_Services_Logo.svg/512px-Amazon_Web_Services_Logo.svg.png',
    companyLogoStyle: 'workexperience-company-logo-aws',
    role: 'Software Development Engineer',
    fromDate: moment([2018, 11, 3]).format('dddd, MMMM Do YYYY'),
    toDate: moment([2020, 0, 6, 17]).format('dddd, MMMM Do YYYY'),
  },
  {
    companyName: 'iKhokha',
    companyLogo: 'https://seeklogo.com/images/I/ikhokha-logo-E74F38C281-seeklogo.com.png',
    companyLogoStyle: 'workexperience-company-logo-ikhokha',
    role: 'Fullstack Software Engineer',
    fromDate: moment([2020, 1, 10, 8]).format('dddd, MMMM Do YYYY'),
    toDate: 'Current', // curerent
  },
];

export const educationData = [
  {
    institutionName: 'University of KwaZulu-Natal',
    institutionLogo:
      'https://www.logosvgpng.com/wp-content/uploads/2021/03/university-of-kwazulu-natal-logo-vector.png',
    qualification: 'Bachelor of Science - Computer Science and Mathematics ',
    degree: '(summa cum laude)',
    fromDate: moment([2015, 1]).format('MMMM YYYY'),
    toDate: moment([2017, 11]).format('MMMM YYYY'),
  },
  {
    institutionName: 'University of KwaZulu-Natal',
    institutionLogo:
      'https://www.logosvgpng.com/wp-content/uploads/2021/03/university-of-kwazulu-natal-logo-vector.png',
    qualification: 'Bachelor of Science (Honours) - Computer Science',
    fromDate: moment([2018, 1]).format('MMMM YYYY'),
    toDate: moment([2018, 11]).format('MMMM YYYY'),

    project: '',
  },
];

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/nkosenhle-duma-bb2341aa/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/NkosenhleDuma',
    },
  ],
};
